import React, { Component } from "react";
import { getErrorMessage } from "../../../helpers";
import Loader from "../../../components/UI/Loader";
import { TextInput } from "../../../components/UI/TextInput";
import { Button } from "../../../components/UI/Button";
import ErrorAlert from "../../../components/errorAlert";
import { checkResetPassValidationErrors } from "../form-validation";
import { changeUserPassword } from "../utils";
import { changeUserPasswordURI } from "../../../shared/mockData";

class PasswordSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPass: false,
      loading: false,
      resetFields: {},
      resetErrors: {},
      apiErrorMsg: "",
      showError: false,
    };
  }

  resetPassSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const resetErrors = checkResetPassValidationErrors(this.state.resetFields);
    if (resetErrors) {
      this.setState({ loading: false, resetErrors, editPass: true });

      console.log("Form has errors.");
    } else {
      this.resetPass();
    }
  }

  handlePassChange(field, e) {
    let fields = this.state.resetFields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  resetPass = () => {
    const { resetFields } = this.state;
    const body = {
      currentPassword: resetFields["oldPassword"],
      newPassword: resetFields["password"],
    };
    const errorState = {
      showError: false,
    };
    changeUserPassword(changeUserPasswordURI, body)
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages);
        }
        return res.data;
      })
      .then((data) => {
        this.setState({
          editPass: false,
          loading: false,
          showError: false,
          resetFields: {},
        });
        return;
      })
      .catch((error) => {
        errorState.showError = true;
        errorState.apiErrorMsg = getErrorMessage(error);
        errorState.loading = false;
        this.setState(errorState);
      });
  };

  render() {
    const {
      editPass,
      loading,
      showError,
      apiErrorMsg,
      resetFields,
      resetErrors,
    } = this.state;
    const { resetPassSubmit, handlePassChange } = this;

    return (
      <>
        <p className="user-pass-text">Գաղտնաբառ</p>
        <div className="user-pass">
          {editPass ? (
            <>
              <form onSubmit={resetPassSubmit.bind(this)}>
                <div className="user-detail">
                  <span>Ընթացիկ գաղտնաբառը</span>
                  <TextInput
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={resetErrors["oldPassword"]}
                    placeholder="Ընթացիկ գաղտնաբառ *"
                    type="password"
                    onChange={handlePassChange.bind(this, "oldPassword")}
                    value={resetFields["oldPassword"] || ""}
                    profileZone
                  />
                </div>
                <div className="user-detail">
                  <span>Նոր գաղտնաբառը</span>
                  <TextInput
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={resetErrors["password"]}
                    placeholder="Նոր գաղտնաբառը *"
                    type="password"
                    onChange={handlePassChange.bind(this, "password")}
                    value={resetFields["password"] || ""}
                    profileZone
                  />
                </div>
                <div className="user-detail">
                  <span>Հաստատեք նոր գաղտնաբառը</span>
                  <TextInput
                    contentClassName="user-input input-wrapper"
                    className={""}
                    hasError={resetErrors["cPassword"]}
                    placeholder="Հաստատեք նոր գաղտնաբառը *"
                    type="password"
                    onChange={handlePassChange.bind(this, "cPassword")}
                    value={resetFields["cPassword"] || ""}
                    profileZone
                  />
                </div>

                <div className="user-form-btns">
                  <Button
                    className="user-form-btn link"
                    action={() =>
                      this.setState({ editPass: false, resetFields: {} })
                    }
                    text="Չեղարկել"
                  />
                  <Button
                    className="user-form-btn save"
                    action={resetPassSubmit.bind(this)}
                    text="Պահպանել"
                  />
                </div>
              </form>
              {loading && <Loader />}

              {showError && (
                <ErrorAlert
                  text={apiErrorMsg}
                  close={() => this.setState({ showError: false })}
                />
              )}
            </>
          ) : (
            <Button
              className="user-pass-btn"
              action={() => this.setState({ editPass: true })}
              text="Փոխել գաղտնաբառը"
            />
          )}
        </div>
      </>
    );
  }
}
export default PasswordSideBar;
