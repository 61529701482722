import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'

import { userReducer } from './reducers/user'
import { topicsReducer } from './reducers/topics'
import {
    categoriesReducer,
    filteredCategoriesReducer,
} from './reducers/categories'
import { filteredSubjectsReducer, subjectsReducer } from './reducers/subjects'
import { authReducer } from './reducers/auth'
import { filteredGradesReducer, gradesReducer } from './reducers/grades'
import { schoolsReducer } from './reducers/schools'
import { countriesReducer } from './reducers/countries'
import { regionsReducer } from './reducers/regions'
import { statesReducer } from './reducers/states'
import middleWares from './middleWares/index'
import { lessonsReducer } from './reducers/lessons'
import { menuReducer } from './reducers/menu'
import { aboutReducer } from './reducers/about'
import { searchReducer } from './reducers/search'
import { mapStyleReducer } from './reducers/mapStyle'
import { hintsReducer } from './reducers/hints'
import { mapRequirementReducer } from './reducers/mapRequirement'
import { menuExpendReducer } from './reducers/menuExpend'
import { mapStylesReducer } from './reducers/mapStyles'
import { articlesReducer } from './reducers/articles'
import { lampReducer } from './reducers/lamp'
import {bounceReducer, zoomReducer} from './reducers/zoom'
import { timeLineExpendReducer } from './reducers/timeLineExpend'
import { mapRequirementsReducer } from './reducers/mapRequirements'
import { timeLineOptionsReducer } from './reducers/timeLineOptions'
import {
    handleLessonIdReducer,
    handleNextLessonIdReducer,
    handlePrevLessonIdReducer,
    handleSelectLessonReducer,
} from './reducers/handleLessonId'
import {
    handleTimeLineGroupsReducer,
    handleTimeLineItemsReducer,
} from './reducers/timeLine'

const getInitialState = () => {}

const rootReducer = combineReducers({
    user: userReducer,
    categories: categoriesReducer,
    filteredCategories: filteredCategoriesReducer,
    topics: topicsReducer,
    lessons: lessonsReducer,
    subjects: subjectsReducer,
    filteredSubjects: filteredSubjectsReducer,
    grades: gradesReducer,
    filteredGrades: filteredGradesReducer,
    schools: schoolsReducer,
    countries: countriesReducer,
    regions: regionsReducer,
    states: statesReducer,
    auth: authReducer,
    menu: menuReducer,
    search: searchReducer,
    mapStyle: mapStyleReducer,
    hints: hintsReducer,
    mapRequirement: mapRequirementReducer,
    about: aboutReducer,
    menuExpend: menuExpendReducer,
    timeLineExpend: timeLineExpendReducer,
    mapStyles: mapStylesReducer,
    mapRequirements: mapRequirementsReducer,
    articles: articlesReducer,
    lampArticles: lampReducer,
    selectedArticle: articlesReducer,
    mapZoom: zoomReducer,
    mapBounce: bounceReducer,
    timeLineOptions: timeLineOptionsReducer,
    prevLessonId: handlePrevLessonIdReducer,
    nextLessonId: handleNextLessonIdReducer,
    timeLineItems: handleTimeLineItemsReducer,
    timeLineGroups: handleTimeLineGroupsReducer,
    selectedLesson: handleSelectLessonReducer,
})

const configureStore = (initialState) => {
    const store = createStore(
        rootReducer,
        initialState,
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? compose(
                  applyMiddleware(thunk, ...middleWares),
                  window.__REDUX_DEVTOOLS_EXTENSION__()
              )
            : compose(applyMiddleware(thunk, ...middleWares))
    )

    return store
}
export const store = configureStore(getInitialState())
