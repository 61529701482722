export const LOADING_SUBJECTS = 'load-subjects'
export const SET_SUBJECTS = 'set-subjects'

export const LOADING_FILTERED_SUBJECTS = 'load-filtered-subjects'
export const SET_FILTERED_SUBJECTS = 'set-filtered-subject'

export const LOADING_GRADES = 'load-grades'
export const SET_GRADES = 'set-grades'

export const LOADING_FILTERED_GRADES = 'load-filtered-grades'
export const SET_FILTERED_GRADES = 'set-filtered-grades'

export const LOADING_SCHOOLS = 'load-schools'
export const SET_SCHOOLS = 'set-schools'

export const LOADING_STATES = 'load-states'
export const SET_STATES = 'set-states'

export const LOADING_TOPICS = 'load-topics'
export const SET_TOPICS = 'set-topics'

export const LOADING_COUNTRIES = 'load-countries'
export const SET_COUNTRIES = 'set-countries'

export const LOADING_REGIONS = 'load-regions'
export const SET_REGIONS = 'set-regions'

export const LOADING_LESSONS = 'load-lessons'
export const SET_LESSONS = 'set-lessons'

export const SET_USER = 'set-user'
export const SET_TOKENS = 'set-tokens'
export const LOGOUT_USER = 'logout'

export const SET_MENU = 'set-menu'
export const SET_ABOUT = 'set-about'
export const SET_MAP_STYLE = 'set-map-style'
export const SET_SEARCH = 'set-search'
export const SET_HINTS = 'set-hints'
export const SET_MAP_REQUIREMENT = 'set-map-requirement'
export const SET_MENU_EXPEND = 'set-menu-expend'
export const SET_TIMELINE_EXPEND = 'set-timeline-expend'

export const CHANGE_MAP_STYLES = 'change-map-styles'
export const CHANGE_MAP_REQUIREMENTS = 'change-map-requirements'

export const LOADING_ARTICLES = 'load-articles'
export const SET_ARTICLES = 'set-articles'
export const SET_SELECTED_ARTICLE = 'set-selected-article'

export const CHANGE_MAP_ZOOM = 'set-map-zoom'

export const CHANGE_MAP_BOUNCE = 'set-map-bounce'

export const LOADING_LAMP = 'load-lamp'
export const SET_LAMP = 'set-lamp'

export const SET_CATEGORIES = 'set-categories'
export const SET_FILTERED_CATEGORIES = 'set-filtered-categories'

export const SET_TIMELINE_OPTIONS = 'set-timeline-options'

export const SET_NEXT_LESSON = 'set-next-lesson'
export const SET_PREV_LESSON = 'set-prev-lesson'

export const SET_TIMELINE_ITEMS = 'set-timeline-items'
export const SET_TIMELINE_GROUPS = 'set-timeline-groups'
export const LOADING_TIMELINE = 'load-timeline'

export const SET_LESSON_ID = 'set-lesson-id'
