import React, {Component, createElement, createRef, useCallback} from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import './style.css'


class Gallery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            galleryPaginationActive: 1,
            isFull:false,
            fullElement:null,
            start:false,
            tili:false,
            toSlide: {
                active:true
            }
        }
        this.galleryContainer = createRef(<Splide />)
        this.galleryThumb = createRef(<Splide />)
        this.galleryCaption = createRef(<Splide />)
        this.fullScreenRef = createRef(<div />)
        this.galleryRef = createRef(<div />)
        this.galleryTiliRef = createRef(<div />)
        this.gallerySectionRef = createRef(<div />)
        this.galleryTiliContainer = createRef(<Splide />)
    }

    componentDidMount() {
        if (
            this.galleryContainer.current &&
            this.galleryCaption.current &&
            this.galleryThumb.current &&
            this.galleryThumb.current.splide
        ) {
            this.galleryContainer.current.sync(this.galleryCaption.current.splide)
            this.galleryContainer.current.sync(this.galleryThumb.current.splide)

        }
    }
    gotoItem = () => {
        if(this.state.toSlide.active) return
        setTimeout(() => {
            let mediaId = this.props.clickedMediaImage
            let index = this.props.galleryItems
                ?.filter((x) => x.type !== 4)
                ?.findIndex((x) => x.mediaId === mediaId)
            this.galleryThumb.current?.splide?.go(
                this.props.feauturedImage !== null ? index + 1 : index
        )
        }, 2000)
    }
    renderSlides = () => {
        this.gotoItem()
        return (
            <>
             {/*   {this.props.feauturedImage !== null ? (*/}
             {/*       <SplideSlide*/}
             {/*           key={'featured'}*/}
             {/*           className="gallery-item"*/}
             {/*           id={'featured'}*/}
             {/*       >*/}
             {/*           <img*/}
             {/*               src={this.props.feauturedImage}*/}
             {/*               alt="image_placeholder"*/}
             {/*           />*/}
             {/*       </SplideSlide>*/}
             {/*) : null}**/}
               {this.props.galleryItems
                ?.filter((x) => x.type !== 4)
                   ?.map((slide) => (
                       <SplideSlide
                           key={slide?.path}
                           className={`gallery-item single-gallery ${slide.mediaId} `}
                        >
                            {slide.type === 0 ? (
                                <>
                                    <img
                                        src={slide?.path}
                                        alt={slide.caption}
                                    />
                                </>
                            ) : slide.type === 1 ? (
                                <>
                                    <video data-item = {true} src={slide?.path} controls/>
                                </>
                            ) : slide.type === 3 ? (
                                    <>
                                        <iframe
                                            src={slide?.path}
                                            title="YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        />
                                    </>
                            ) : null}
                        </SplideSlide>
                    ))}
            </>
        )
    }
    renderNavigationSlides = () => {
        this.gotoItem()

        return (
            <>
                {this.props.galleryItems
                    ?.filter((x) => x.type !== 4)
                    ?.map((slide) => (
                        <SplideSlide
                            key={slide?.path}
                            className={`gallery-item single-gallery ${slide.mediaId} `}
                        >
                            {slide.type === 0 ? (
                                <div className={"single-gallery-img"}>
                                    <img
                                        src={slide?.path}
                                        alt={slide.caption}
                                    />
                                </div >
                            ) : slide.type === 1 ? (
                                <div className={"single-gallery-video"}>
                                    <video data-item = {true} src={slide?.path} className="hiddConroll" controls="hidden" style={{'pointerEvents': 'none'}}/>
                                    <div className={"single-gallery-video-icon"}>
                                        <svg xmlns="http://www.w3.org/2000/svg"  width="260px" height="260px" viewBox="0 0 260 260" version="1.1">
                                        <title>video</title>
                                        <defs>
                                            <filter x="-201.2%" y="-201.2%" width="502.5%" height="502.5%" filterUnits="objectBoundingBox" id="filter-1">
                                                <feOffset dx="0" dy="14" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                                <feGaussianBlur stdDeviation="26" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                                <feColorMatrix values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                                <feMerge>
                                                    <feMergeNode in="shadowMatrixOuter1"/>
                                                    <feMergeNode in="SourceGraphic"/>
                                                </feMerge>
                                            </filter>
                                        </defs>
                                        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.8" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                            <g id="Read-more" transform="translate(-1820.000000, -1708.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="6">
                                                <g id="Group-53" transform="translate(208.939531, 182.000000)">
                                                    <g id="Group-52" transform="translate(0.060469, 1496.000000)">
                                                        <g id="Group-3" transform="translate(1499.956434, 0.289943)">
                                                            <g id="vuesax/linear/video-square" transform="translate(203.902627, 127.102368)">
                                                                <g transform="translate(-0.043947, 0.175788)" id="video" filter="url(#filter-1)">
                                                                    <path d="M41.9912836,64.6019748 C58.1417773,64.6019748 64.6019748,58.1417773 64.6019748,41.9912836 L64.6019748,22.6106912 C64.6019748,6.46019748 58.1417773,0 41.9912836,0 L22.6106912,0 C6.46019748,0 0,6.46019748 0,22.6106912 L0,41.9912836 C0,58.1417773 6.46019748,64.6019748 22.6106912,64.6019748 L41.9912836,64.6019748 Z" id="Vector"/>
                                                                    <path d="M22.9340165,27.5204135 C22.9340165,21.350925 27.2946499,18.8637475 32.6243127,21.9323412 L36.7588382,24.3226134 L40.8933637,26.7128888 C46.2230266,29.7814825 46.2230266,34.8204337 40.8933637,37.8890275 L36.7588382,40.2793029 L32.6243127,42.6695752 C27.2946499,45.738169 22.9340165,43.2186903 22.9340165,37.0815028 L22.9340165,32.3009581 L22.9340165,27.5204135 Z" id="Vector"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    </div>
                                </div>
                            ) : slide.type === 3 ? (
                                <div className={"single-gallery-iframe"}>
                                    <iframe
                                        src={slide?.path}
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    />
                                </div>
                            ) : null}
                        </SplideSlide>
                    ))}
            </>
        )
    }

    renderTiliSlides = () => {
        // setTimeout(() => {
        //     let mediaId = this.props.clickedMediaImage
        //     let index = this.props.galleryItems
        //         ?.filter((x) => x.type !== 4)
        //         ?.findIndex((x) => x.mediaId === mediaId)
        //     this.galleryTiliContainer.current?.splide?.go(
        //         this.props.feauturedImage !== null ? index + 1 : index
        //     )
        // }, 2000)
        return (
            <>
             {/*   {this.props.feauturedImage !== null ? (*/}
             {/*       <SplideSlide*/}
             {/*           key={'featured'}*/}
             {/*           className="gallery-item"*/}
             {/*           id={'featured'}*/}
             {/*       >*/}
             {/*           <img*/}
             {/*               src={this.props.feauturedImage}*/}
             {/*               alt="image_placeholder"*/}
             {/*           />*/}
             {/*       </SplideSlide>*/}
             {/*) : null}**/}
                <SplideSlide
                    className={`gallery-item `}
                >
                <div className="tili-item">
                   {this.props.galleryItems
                    ?.filter((x) => x.type !== 4)
                       ?.map((slide,idx) => (
                                slide.type === 0 ? (
                                    <div key={slide.id} className="gallery-block" onClick={(e)=>
                                        // this.toggleFullScreenFromTili(true,slide?.path,'img')
                                        this.switchToSlide(idx)
                                    }>
                                        <img
                                            src={slide?.path}
                                            alt={slide.caption}
                                        />
                                        <div
                                            className={'gallery-title'}
                                        >
                                            <p>{slide.caption}</p>
                                        </div>
                                    </div>
                                ) : slide.type === 1 ? (
                                    <div key={slide.id} className="gallery-block hiddConroll" onClick={(e)=>
                                        // this.toggleFullScreenFromTili(true,slide?.path,'video')
                                        this.switchToSlide(idx)
                                    }>
                                        <video data-item = {true} src={slide?.path} className="hiddConroll" controls="hidden" style={{'pointerEvents': 'none'}}/>
                                        <div
                                            className={'gallery-title'}
                                        >
                                            <p>{slide.caption}</p>
                                        </div>
                                        <div className={"single-gallery-video-icon"}>
                                            <svg xmlns="http://www.w3.org/2000/svg"  width="260px" height="260px" viewBox="0 0 260 260" version="1.1">
                                                <title>video</title>
                                                <defs>
                                                    <filter x="-201.2%" y="-201.2%" width="502.5%" height="502.5%" filterUnits="objectBoundingBox" id="filter-1">
                                                        <feOffset dx="0" dy="14" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                                        <feGaussianBlur stdDeviation="26" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                                        <feColorMatrix values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                                        <feMerge>
                                                            <feMergeNode in="shadowMatrixOuter1"/>
                                                            <feMergeNode in="SourceGraphic"/>
                                                        </feMerge>
                                                    </filter>
                                                </defs>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.8" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                                    <g id="Read-more" transform="translate(-1820.000000, -1708.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="6">
                                                        <g id="Group-53" transform="translate(208.939531, 182.000000)">
                                                            <g id="Group-52" transform="translate(0.060469, 1496.000000)">
                                                                <g id="Group-3" transform="translate(1499.956434, 0.289943)">
                                                                    <g id="vuesax/linear/video-square" transform="translate(203.902627, 127.102368)">
                                                                        <g transform="translate(-0.043947, 0.175788)" id="video" filter="url(#filter-1)">
                                                                            <path d="M41.9912836,64.6019748 C58.1417773,64.6019748 64.6019748,58.1417773 64.6019748,41.9912836 L64.6019748,22.6106912 C64.6019748,6.46019748 58.1417773,0 41.9912836,0 L22.6106912,0 C6.46019748,0 0,6.46019748 0,22.6106912 L0,41.9912836 C0,58.1417773 6.46019748,64.6019748 22.6106912,64.6019748 L41.9912836,64.6019748 Z" id="Vector"/>
                                                                            <path d="M22.9340165,27.5204135 C22.9340165,21.350925 27.2946499,18.8637475 32.6243127,21.9323412 L36.7588382,24.3226134 L40.8933637,26.7128888 C46.2230266,29.7814825 46.2230266,34.8204337 40.8933637,37.8890275 L36.7588382,40.2793029 L32.6243127,42.6695752 C27.2946499,45.738169 22.9340165,43.2186903 22.9340165,37.0815028 L22.9340165,32.3009581 L22.9340165,27.5204135 Z" id="Vector"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                ) : slide.type === 3 ? (
                                    <div className="tili-iframe" key={slide.id} onClick={(e)=>
                                        // this.toggleFullScreenFromTili(true,slide?.path,'iframe')
                                        this.switchToSlide(idx)
                                    }>
                                        <div className="tili-iframe-item">
                                            <iframe
                                                src={slide?.path}
                                                title="YouTube video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            />
                                        </div>
                                    </div>
                                ) : null
                        ))}
                   </div>
                </SplideSlide>

            </>
        )
    }
    renderSlidesCaption = () => {
        this.gotoItem()
        return (
            <>
                {this.props.galleryItems
                    ?.filter((x) => x.type !== 4)
                    ?.map((slide) => (
                        <SplideSlide
                            key={slide?.path}
                            className={`gallery-item ${slide.mediaId}`}
                        >
                            {slide.type === 0 ? (
                                <>
                                    <div className={'gallery-caption'}>
                                        <p>{slide.caption}</p>
                                    </div>
                                </>
                            ) : slide.type === 1 ? (
                                <>
                                    <div className={'gallery-caption'}>
                                        <p>{slide.caption}</p>
                                    </div>
                                </>
                            ) : slide.type === 3 ? (
                                <>
                                    <div className={'gallery-caption'}>
                                        <p>{slide.caption}</p>
                                    </div>
                                </>
                            ) : null}
                        </SplideSlide>
                    ))}
            </>
        )
    }
    toggleFullScreenFromTili = (action,path,tag) =>{
        if(!action) {
            // this.galleryRef.current.setAttribute('data-full-screen',false)
            this.gallerySectionRef.current.setAttribute('data-full-screen',false)
            // this.setState({isFull:action})
            return
        }
        this.gallerySectionRef.current.setAttribute('data-full-screen',true)
        let element = document.createElement(tag)
        element.setAttribute('src',path)
        if(tag === 'video') element.setAttribute('controls',true)
        const _this = this
        setTimeout(()=>{
            _this.fullScreenRef.current.innerHTML = element.outerHTML
        },0)

    }
    switchToSlide = (idx) => {
        this.state.toSlide.active = true
        setTimeout(() => {
            this.toggleMode()
            let index = idx
            this.galleryThumb.current?.splide?.go(
                this.props.feauturedImage !== null ? index + 1 : index
            )
            this.state.toSlide.active = false
        }, 200)
    }
    toggleFullScreen = (action) =>{
        if(!action) {
            // this.galleryRef.current.setAttribute('data-full-screen',false)
            this.gallerySectionRef.current.setAttribute('data-full-screen',false)
            // this.setState({isFull:action})
            return
        }
        const element = document.getElementsByClassName('single-gallery is-active')[0].children[0]

        // this.galleryRef.current.setAttribute('data-full-screen',true)
        this.gallerySectionRef.current.setAttribute('data-full-screen',true)
        // this.setState({isFull:action})
        const _this = this
        setTimeout(()=>{
            _this.fullScreenRef.current.innerHTML = element.outerHTML
        },0)
    }
    toggleMode = () =>{
    this.setState({tili:!this.state.tili})
    }

    render() {
        const { title,gallery, galleryItems, toggleGallery } = this.props
        return (
            <div className={`gallery-section`} ref={this.gallerySectionRef}>
                    <div data-tili={this.state.tili} className={`gallery tili active`} data-full-screen="false" ref={this.galleryTiliRef}>
                        <div className="gallery-header">
                            <div  className="gallery-header-title">{title}</div>
                            <button
                                className="gallery-header-btn gallery-close"
                                type="button"
                                onClick={toggleGallery}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="61px" height="61px" viewBox="0 0 61 61" version="1.1">
                                    <title>Group 2</title>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                        <g id="Read-more" transform="translate(-3726.000000, -51.000000)" stroke="#FFFFFF" stroke-width="4.9">
                                            <g id="Group-2" transform="translate(3726.000000, 55.000000)">
                                                <line x1="-6.58813919" y1="26.1911362" x2="67.5881392" y2="26.4242484" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                                <line x1="30.6165561" y1="63.3958315" x2="30.3834439" y2="-10.7804469" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div  className="splide-content">
                            <Splide
                                ref={this.galleryTiliContainer}
                                options={{
                                    type: 'loop',
                                    gap:'10px',
                                    pagination: false,
                                    fixedWidth:'90vw',
                                    fixedHeight:'90vh',
                                    height: '90vh',
                                    width: '90vw',
                                    arrows: false,
                                }}
                                renderControls={() => (
                                    <div
                                        className="splide__arrows"
                                        style={{
                                            justifyContent: 'center',
                                            display: 'flex',
                                        }}>
                                        <button
                                            className="gallery-btn prev-main splide__arrow--prev"
                                            type="button"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="94px" height="124px" viewBox="0 0 94 124" version="1.1">
                                                <title>arrow-right</title>
                                                <defs>
                                                    <filter x="-139.5%" y="-69.4%" width="379.1%" height="238.8%" filterUnits="objectBoundingBox" id="filter-1">
                                                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                                        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                                        <feColorMatrix values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                                        <feMerge>
                                                            <feMergeNode in="shadowMatrixOuter1"/>
                                                            <feMergeNode in="SourceGraphic"/>
                                                        </feMerge>
                                                    </filter>
                                                </defs>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                                    <g id="Read-more" transform="translate(-229.000000, -859.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="5.28">
                                                        <g id="arrow-right" filter="url(#filter-1)" transform="translate(276.000000, 920.921378) rotate(-540.000000) translate(-276.000000, -920.921378) translate(261.078622, 890.921378)">
                                                            <path d="M0,0 L29.8427564,30 L0,60" id="Vector"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                        <button
                                            className="gallery-btn next-main splide__arrow--next"
                                            type="button"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"  width="94px" height="124px" viewBox="0 0 94 124" version="1.1">
                                                <title>arrow-right copy</title>
                                                <defs>
                                                    <filter x="-139.5%" y="-69.4%" width="379.1%" height="238.8%" filterUnits="objectBoundingBox" id="filter-1">
                                                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                                        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                                        <feColorMatrix values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                                        <feMerge>
                                                            <feMergeNode in="shadowMatrixOuter1"/>
                                                            <feMergeNode in="SourceGraphic"/>
                                                        </feMerge>
                                                    </filter>
                                                </defs>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                                    <g id="Read-more" transform="translate(-2578.000000, -853.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="5.28">
                                                        <g id="arrow-right-copy" filter="url(#filter-1)" transform="translate(2625.000000, 915.042653) rotate(-360.000000) translate(-2625.000000, -915.042653) translate(2610.078622, 885.042653)">
                                                            <path d="M0,0 L29.8427564,30 L0,60" id="Vector"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                )}
                                className="gallery-main"
                            >
                                {this.state.tili && gallery ? this.renderTiliSlides() : null}
                            </Splide>
                        </div>

                    </div>

                    <div data-tili={!this.state.tili} className={`gallery single active`} data-full-screen="false" ref={this.galleryRef}>
                        <div className="gallery-header">
                            <div  className="gallery-header-title">{title}</div>
                            <button
                                className="gallery-header-btn gallery-tili"
                                type="button"
                                onClick={this.toggleMode}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="67px" height="67px" viewBox="0 0 67 67" version="1.1">
                                    <title>element-3</title>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Read-more" transform="translate(-3438.000000, -48.000000)">
                                            <g id="element-3" transform="translate(3438.000000, 48.000000)">
                                                <path d="M61.4166667,11.1108334 C61.4166667,7.17458348 59.6300014,5.58333333 55.1912513,5.58333333 L43.9129154,5.58333333 C39.4741653,5.58333333 37.6875,7.17458348 37.6875,11.1108334 L37.6875,23.757084 C37.6875,27.7212505 39.4741653,29.2845827 43.9129154,29.2845827 L55.1912513,29.2845827 C59.6300014,29.3124994 61.4166667,27.7212512 61.4166667,23.7850013 L61.4166667,11.1108334 Z" id="Vector" stroke="#FFFFFF" stroke-width="4.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"/>
                                                <path d="M61.4166667,43.9129154 C61.4166667,39.4741653 59.6300014,37.6875 55.1912513,37.6875 L43.9129154,37.6875 C39.4741653,37.6875 37.6875,39.4741653 37.6875,43.9129154 L37.6875,55.1912513 C37.6875,59.6300014 39.4741653,61.4166667 43.9129154,61.4166667 L55.1912513,61.4166667 C59.6300014,61.4166667 61.4166667,59.6300014 61.4166667,55.1912513 L61.4166667,43.9129154 Z" id="Vector" stroke="#FFFFFF" stroke-width="4.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"/>
                                                <path d="M29.3125,11.1108334 C29.3125,7.17458348 27.5258347,5.58333333 23.0870846,5.58333333 L11.8087501,5.58333333 C7.36999996,5.58333333 5.58333333,7.17458348 5.58333333,11.1108334 L5.58333333,23.757084 C5.58333333,27.7212505 7.36999996,29.2845827 11.8087501,29.2845827 L23.0870846,29.2845827 C27.5258347,29.3124994 29.3125,27.7212512 29.3125,23.7850013 L29.3125,11.1108334 Z" id="Vector" stroke="#FFFFFF" stroke-width="4.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"/>
                                                <path d="M29.3125,43.9129154 C29.3125,39.4741653 27.5258347,37.6875 23.0870846,37.6875 L11.8087501,37.6875 C7.36999996,37.6875 5.58333333,39.4741653 5.58333333,43.9129154 L5.58333333,55.1912513 C5.58333333,59.6300014 7.36999996,61.4166667 11.8087501,61.4166667 L23.0870846,61.4166667 C27.5258347,61.4166667 29.3125,59.6300014 29.3125,55.1912513 L29.3125,43.9129154 Z" id="Vector" stroke="#FFFFFF" stroke-width="4.2" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="0,0" fill-rule="nonzero"/>
                                                <path d="M67,0 L67,67 L0,67 L0,0 L67,0 Z" id="Vector" opacity="0"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <button
                                className="gallery-header-btn gallery-maximize"
                                type="button"
                                onClick={()=>this.toggleFullScreen(true)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="59px" height="59px" viewBox="0 0 59 59" version="1.1">
                                    <title>maximize-4</title>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                        <g id="Read-more" transform="translate(-3586.000000, -52.000000)" stroke="#FFFFFF" stroke-width="4.9">
                                            <g id="maximize-4" transform="translate(3589.000000, 55.000000)">
                                                <path d="M53,18.1315789 L53,0 L34.8684211,0" id="Vector" fill-rule="nonzero"/>
                                                <path d="M0,34.8684211 L0,53 L18.1315789,53" id="Vector" fill-rule="nonzero"/>
                                                <line x1="53" y1="0" x2="30.6842105" y2="22.3157895" id="Vector"/>
                                                <line x1="22.3157895" y1="30.6842105" x2="0" y2="53" id="Vector"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                            <button
                                className="gallery-header-btn gallery-close"
                                type="button"
                                onClick={toggleGallery}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="61px" height="61px" viewBox="0 0 61 61" version="1.1">
                                    <title>Group 2</title>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                        <g id="Read-more" transform="translate(-3726.000000, -51.000000)" stroke="#FFFFFF" stroke-width="4.9">
                                            <g id="Group-2" transform="translate(3726.000000, 55.000000)">
                                                <line x1="-6.58813919" y1="26.1911362" x2="67.5881392" y2="26.4242484" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                                <line x1="30.6165561" y1="63.3958315" x2="30.3834439" y2="-10.7804469" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div  className="gallery-content splide-content">
                            <Splide
                                ref={this.galleryContainer}
                                options={{
                                    type: 'loop',
                                    perPage: 1,
                                    perMove: 1,
                                    pagination: false,
                                    height: '70vh',
                                    arrows: false,
                                }}
                                renderControls={() => (
                                    <div
                                        className="splide__arrows"
                                        style={{
                                            justifyContent: 'center',
                                            display: 'flex',
                                        }}>
                                        <button
                                            className="gallery-btn prev-main splide__arrow--prev"
                                            type="button"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="94px" height="124px" viewBox="0 0 94 124" version="1.1">
                                                <title>arrow-right</title>
                                                <defs>
                                                    <filter x="-139.5%" y="-69.4%" width="379.1%" height="238.8%" filterUnits="objectBoundingBox" id="filter-1">
                                                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                                        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                                        <feColorMatrix values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                                        <feMerge>
                                                            <feMergeNode in="shadowMatrixOuter1"/>
                                                            <feMergeNode in="SourceGraphic"/>
                                                        </feMerge>
                                                    </filter>
                                                </defs>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                                    <g id="Read-more" transform="translate(-229.000000, -859.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="5.28">
                                                        <g id="arrow-right" filter="url(#filter-1)" transform="translate(276.000000, 920.921378) rotate(-540.000000) translate(-276.000000, -920.921378) translate(261.078622, 890.921378)">
                                                            <path d="M0,0 L29.8427564,30 L0,60" id="Vector"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                        <button
                                            className="gallery-btn next-main splide__arrow--next"
                                            type="button"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"  width="94px" height="124px" viewBox="0 0 94 124" version="1.1">
                                                <title>arrow-right copy</title>
                                                <defs>
                                                    <filter x="-139.5%" y="-69.4%" width="379.1%" height="238.8%" filterUnits="objectBoundingBox" id="filter-1">
                                                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"/>
                                                        <feGaussianBlur stdDeviation="10" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                                        <feColorMatrix values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                                        <feMerge>
                                                            <feMergeNode in="shadowMatrixOuter1"/>
                                                            <feMergeNode in="SourceGraphic"/>
                                                        </feMerge>
                                                    </filter>
                                                </defs>
                                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                                    <g id="Read-more" transform="translate(-2578.000000, -853.000000)" fill-rule="nonzero" stroke="#FFFFFF" stroke-width="5.28">
                                                        <g id="arrow-right-copy" filter="url(#filter-1)" transform="translate(2625.000000, 915.042653) rotate(-360.000000) translate(-2625.000000, -915.042653) translate(2610.078622, 885.042653)">
                                                            <path d="M0,0 L29.8427564,30 L0,60" id="Vector"/>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                )}
                                className="gallery-main"
                            >
                                {gallery ? this.renderSlides() : null}
                            </Splide>
                            <Splide
                                ref={this.galleryThumb}
                                options={{
                                    type: 'loop',
                                    rewind: false,
                                    perPage: 5,
                                    perMove:1,
                                    gap: '0',
                                    pagination: false,
                                    fixedWidth:250,
                                    height: "15vh",
                                    arrows: false,
                                    isNavigation: true,
                                    focus: 'center',
                                }}
                                className="gallery-items"
                            >
                                {gallery ? this.renderNavigationSlides() : null}
                            </Splide>
                            <Splide
                                ref={this.galleryCaption}
                                options={{
                                    type: 'loop',
                                    perPage: 1,
                                    pagination: false,
                                    height: '100%',
                                    width:'100%',
                                    arrows: false,
                                    drag:false
                                }}
                                className={'gallery-text-content'}
                            >
                                {gallery ? this.renderSlidesCaption() : null}
                            </Splide>
                        </div>
                    </div>
                    <div className="full-screen-gallery-item">
                    <div className="gallery-header full-screen-header ">
                        <button
                            className="gallery-header-btn gallery-maximize"
                            type="button"
                            onClick={()=>this.toggleFullScreen(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg"  width="59px" height="59px" viewBox="0 0 59 59" version="1.1">
                                <title>maximize-4</title>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                    <g id="Full-screen" transform="translate(-3586.000000, -52.000000)" stroke="#FFFFFF" stroke-width="4.9">
                                        <g id="maximize-4" transform="translate(3589.000000, 55.000000)">
                                            <path d="M22,49.1315789 L22,31 L3.86842105,31" id="Vector" fill-rule="nonzero"/>
                                            <path d="M31,3.86842105 L31,22 L49.1315789,22" id="Vector" fill-rule="nonzero"/>
                                            <line x1="53" y1="0" x2="30.6842105" y2="22.3157895" id="Vector"/>
                                            <line x1="22.3157895" y1="30.6842105" x2="0" y2="53" id="Vector"/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                        <button
                            className="gallery-header-btn gallery-close"
                            type="button"
                            onClick={toggleGallery}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="61px" height="61px" viewBox="0 0 61 61" version="1.1">
                                <title>Group 2</title>
                                <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-dasharray="0,0" stroke-linecap="round" stroke-linejoin="round">
                                    <g id="Read-more" transform="translate(-3726.000000, -51.000000)" stroke="#FFFFFF" stroke-width="4.9">
                                        <g id="Group-2" transform="translate(3726.000000, 55.000000)">
                                            <line x1="-6.58813919" y1="26.1911362" x2="67.5881392" y2="26.4242484" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                            <line x1="30.6165561" y1="63.3958315" x2="30.3834439" y2="-10.7804469" id="Vector" transform="translate(30.500000, 26.307692) rotate(-315.000000) translate(-30.500000, -26.307692) "/>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    <div className="gallery-content-full-screen ">
                        <div className="full-screen" ref={this.fullScreenRef}>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Gallery
